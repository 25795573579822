<template>
    <div class="page-content w-100">
    <div class="navbarmobileFix">
        <div class="row g-0">
      <div class="col-sm-12">
        <p class="text-center page-heading">Communities and Groups</p>
      </div>
    </div>
    <div class="d-block d-lg-none">
    <div class="row">
      <div class="col-10 col-sm-9 col-lg-10 mb-0">
        <p class="postdoctorCommunity" style="margin-left: 20px;">Communities of doctors</p>
      </div>
      <div class="col-2 col-sm-3 col-lg-2 mb-0 ">
        <p class="closeButton" @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
    </div>
    <div class="hr-border"></div>
  </div>
            <!-- FOR DESKTOP  -->
            <section class="banner-two2 img-bottom mb-5">
                    <div class="selectdoctor">
                        <h2>Select a doctor's community</h2>
                    </div>
                    <div class="row box-items-space">
                        <div class="col-opt-box" v-for="(user, index) in usersHistory" :key="index" :disabled="isdoctorGroup(user)" :class="[isdoctorGroup(user) ? 'eventNone' : '']" @click="updatedoctorCommunity(user)">
                            <div class="col-fix mt-3 color" >
                                <div class="btn-text">
                                    <div class="box-text">{{ user.firstName }} {{ user.lastName }}</div>
                                    <div v-if="user.practiceIdString">
                                        <p class="subpracticeareaText">{{ user.practiceIdString }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button class="btn btn-actionDoctorGrourps" :class="[isdoctorGroup(user) ? 'disableButton' : '']">{{ isdoctorGroup(user) ? 'Joined' : 'Join community' }}</button>
                            </div>
                        </div>
                    </div>
               
            </section>
    </div>
</div>
</template>
<script>
import { defineComponent } from "vue";
import axios from "axios";
export default defineComponent({
    components: {
    },
    data() {
        return {
            usersHistory: [],
            practiceId: '',
            publicDoctorCommunityData:[],
            publicData:{}
        };
    },
    computed: {
    isdoctorGroup() {
        return (data) => {
            return this.publicData?.doctorsID?.some(item => item.id === data.id);
        };
    },
},
    created: async function () {
        let publicUserId = localStorage.getItem('publicSignUpData')
        this.publicId = JSON.parse(publicUserId);
        this.getUserHistory();
        await this.getPublic();
    },
    methods: {
        GoSetting() {
      this.$router.push({ path: `/user/community-groups` });
    },
        getPublic() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
          axios.get(`${BASE_API_URL}/public-user/${this.publicId}/one`)
                .then((response) => {
                  this.publicData=response.data
                })
        },
        updatedoctorCommunity(data){
                this.publicDoctorCommunityData.push(data)
                this.publicDoctorCommunityData = this.publicDoctorCommunityData.concat(this.publicData.doctorsID)
                const signInPayload ={
                    doctorsID: this.publicDoctorCommunityData,
                }
                const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
               axios
                .put(`${BASE_API_URL}/public-user/${this.publicData.id}/update`, signInPayload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response?.data) {
                        this.getPublic();
                    }})
        },
        async getUserHistory() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
            await axios
                .get(`${BASE_API_URL}/hcps`)
                .then((response) => {
                    if (response?.data) {
                        response?.data?.forEach((data) => {
                            if (data?.status?.status === 'Approved' && data.mobile != '9182701237') {
                                if (data.practiceId) {
                                    const practiceAreaNames = data.practiceId.map(item => item.practiceArea);
                                    if (practiceAreaNames.length > 3) {
                                        data.practiceIdString = practiceAreaNames.slice(0, 3).join(", ") + " ...";
                                    } else {
                                        data.practiceIdString = practiceAreaNames.join(", ");
                                    }
                                }
                                this.usersHistory.push(data);
                            }
                        });
                    }
                });
        },
    }
})

</script>

<style>
.disableButton{
    background-color: #cccccc !important;
    color: #000 !important;
}
.eventNone{
    pointer-events: none !important;
    cursor: auto !important;
}
p.subpracticeareaText {
    font-size: 17px;
    margin-bottom: 0px;
}
div.box-text {
    font-size: 22px;
    color: #00979e;
}
.icon-curve3 {
    width: 100%;
    opacity: 0.5
}
.selectdoctor {
    margin-top: 1rem;
}
.selectdoctor h2 {
    color: #000;
    margin-left: 20px;
    font-size: 25px;
}
.row.box-items-space {
    margin-top: 40px;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 22% 22% 22%;
    justify-content: center;
}
.col-opt-box {
    width: 100%;
    display: grid;
    justify-content: center;
    cursor: pointer;
}
.col-fix.mt-3.color {
    flex-direction: column;
    border: 2px solid #00979e;
    width: 242px;
    height: auto;
    min-height: 160px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    overflow: hidden;
}
.col-box {
    display: flex;
    justify-content: center;
    margin: 40px 40px 0px 40px;
    position: relative;
    bottom: 20px;
}
.btn-text {
    text-align: center;
    margin: 10px;
    padding: 10px;
    /* color: #00979e; */
}
.btn-actionDoctorGrourps {
    background: #00979e;
    color: #fff;
    width: 100%;
    border-radius: inherit;
    font-size: 17px;
    border-bottom: 2px solid #00979e;
    border-left: 2px solid #00979e;
    border-right: 2px solid #00979e;
}
.btn-actionDoctorGrourps:hover {
    background: #00979e;
    color: #fff;
}
@media screen and (max-width:1490px) {
    .row.box-items-space {
        grid-gap: 75px;
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width:992px) {
    .postdoctorCommunity {
    margin-left: 20px;
    margin-top: 1rem;
    color: #000;
    font-weight: 550;
    font-size: 21px;
  }
    .row.box-items-space {
        grid-template-columns: 22% 22% 22%;
    }
    .btn-text {
        text-align: center;
        margin: 0px;
        padding: 2px;
        /* color: #00979e; */
    }

    .col-fix.mt-3.color {
        width: 206px;
        min-height: 143px;
    }
}

@media screen and (max-width:991px) {
    .navbarmobileFix {
        margin-bottom: 4rem;
    }
    .onlinecommunityText h3 {
        font-size: 22px;
    }
    .selectdoctor h2 {        
        font-size: 20px;
    }
}
@media screen and (max-width:768px) {
    .row.box-items-space {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0px;
    }
}
@media screen and (max-width: 620px) {
    .col-fix.mt-3.color {
        width: 190px;
        min-height: 145px;
    }
    div.box-text {
        font-size: 20px;
    }
    p.subpracticeareaText {
        font-size: 15px;
        margin-bottom: 0px;
    }
}
@media screen and (max-width:576px) {
    .onlinecommunityText h3 {
        font-size: 17px
    }
}
@media screen and (max-width:550px) {
    .row.box-items-space {
        margin-top: 10px;
    }
}
@media screen and (max-width:470px) {

    .col-fix.mt-3.color {
        width: 133px;
        min-height: 105px;
    }
    p.subpracticeareaText {
        font-size: 12px;
        margin-bottom: 0px;
    }
    button.btn.btn-actionDoctorGrourps {
        font-size: 14px;
    }
    div.box-text {
        font-size: 15px;
    }
    .btn-text {
        padding: 9px;
    }
}
</style>
